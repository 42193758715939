/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col, UncontrolledTooltip
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class ContactUs extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("contact-us");
         window.scrollTo(0, 0);
    }
    componentWillUnmount() {
        document.body.classList.toggle("contact-us");
    }

    render() {

        const AddressMap=()=>{
            return (
                <div className="google-map-code">
                    <iframe class="float-left" title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d156.71178114955467!2d-0.3281340299386915!3d51.06437806959452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875ea4d8ed61829%3A0xad1fd4c628701a43!2s20%20London%20Rd%2C%20Horsham%20RH12%201AY!5e0!3m2!1sen!2suk!4v1706459822788!5m2!1sen!2suk"
                            width="400" height="300" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            );
        }


        return (
            <>
                <ExamplesNavbar />
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                   <Container>
                       <Row>
                           <Col lg="12" md="6">
                               <h5 className="text-on-back">Contact Us</h5>
                               <p className="profile-description">
                                   <p>Please contact us by visiting our Horsham office,
                                   <a href="tel:01403 379919"> call</a>, dropping us an
                                   <a href="mailto:info@diennslaw.co.uk"> email </a>
                                   or message us on our   <a href="https://www.facebook.com/Diennslaw"> Facebook page</a>
                                           <Button
                                               className="btn-icon btn-round"
                                               color="facebook"
                                               href="https://www.facebook.com/Diennslaw"
                                               id="tooltip982846143"
                                               target="_blank"
                                           >
                                               <i className="fab fa-facebook-square"/>
                                           </Button>
                                           <UncontrolledTooltip delay={0} target="tooltip982846143">
                                               Like us
                                           </UncontrolledTooltip>




                                   </p>

                                   <p><b>Address:</b>
                                       <p></p>
                                       &emsp;&emsp;&emsp;&emsp;&emsp;Dienn's Law Solicitors
                                       <br/>&emsp;&emsp;&emsp;&emsp;&emsp;Ground Floor Suite, 20 London Road
                                       <br/>&emsp;&emsp;&emsp;&emsp;&emsp;Horsham
                                       <br/>&emsp;&emsp;&emsp;&emsp;&emsp;West Sussex
                                       <br/>&emsp;&emsp;&emsp;&emsp;&emsp;RH12 1AY
                                   </p>

                                   <p><b>Phone:</b> <a href="tel:01403 379919">01403 379919 </a>
                                       (Horsham) and <a href="tel:01403 379399">01403 379399</a> (Broadbridge Heath, Horsham)</p>
                                   <p><b>Email:</b>  <a href="mailto:info@diennslaw.co.uk">info@diennslaw.co.uk</a></p>
                                   <p><b>Fax:</b> <a href="fax:01903 298154">01903 298154</a></p>
                               </p>

                               <p>By appointment only you can drop off and pick up documents at our satellite office at <b> 3 Singleton Road, Broadbridge Heath, Horsham, West Sussex RH12 3NP</b></p>

                           <br/>
                               <AddressMap></AddressMap>
                           <br/>
                           <br/>
<p>&emsp;Our opening hours are:
    <p>&emsp;Monday - 09.00 – 17.00</p>
    <p>&emsp;Tuesday – 09.00 – 17.00</p>
    <p>&emsp;Wednesday – 09.00 – 17.00</p>
    <p>&emsp;Thursday – 09.00 – 17.00</p>
    <p>&emsp;Friday – 09.00 – 17.00</p>
    <p>&emsp;Saturday – Closed</p>
    <p>&emsp;Sunday - Closed</p>
</p>

                               <br/>
                               <br/>
                           <br/>




                           <br/>
                               <p>If you would like to make a complaint, please
                                   <a href="/complaints"><b> click here.</b></a></p>


                           </Col>
                       </Row>
                   </Container>
                        </div>
                    </div>

                    <Footer />
            </>
        );
    }
}

export default ContactUs;
