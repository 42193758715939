/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components

import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footer/Footer";
import {Card, CardBody} from "reactstrap";
import AgriculturalGrid from "./AgriculturalGrid";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";
import {
    Container,
    Row,
    Col
} from "reactstrap";
class Agricultural extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("agricultural");
        window.scrollTo(0, 0);
    }
    componentWillUnmount() {
        document.body.classList.toggle("agricultural");
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                        <a href="/home">
                            <ReactLogo />
                        </a>
                    </div>

                    <Container className="align-items-center">

                            <Row>
                                <Col lg="12" md="12">

                            <h5 className="text-on-back">Agricultural & Rural Property</h5>

                            <Card>
                                <CardBody>
                                    <AgriculturalGrid></AgriculturalGrid>
                                </CardBody>
                            </Card>

                                    <p><h4>Our Agricultural Team have over 20 years of experience in facilitating
                                            Specialist agricultural and rural property matters.</h4></p>

                                  <p>If you would like more information or have a query please feel free to
                                      <a href="/contact-us"><b> contact us.</b></a></p>

                                <br/>
                                    <p>
                                        Other Areas of Law:
                                        <ul>
                                            <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                            <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                            <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                            <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                            <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                            <a href="/lpas"><li><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></li></a>
                                            <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                            <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                            <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                        </ul>
                                    </p>
                </Col>
            </Row>
          </Container>
                </div>
                <Footer />
            </>
        );
    }
}

export default Agricultural;

