/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import CQSLogo from "../../cqs.png";
import LexelLogo from "../../lexellogo.jpg"
import linkedInLogo from "../../assets/img/linkedin.png";
class Footer extends React.Component {
  render() {




    return (
      <footer className="footer">
        <Container>
          <Row>
            <Col md="3">
              <h1 className="title">Dienn's Law</h1>
              <br/>
              <br/>
              <br/>
              <div className="lexel-logo">
                <img src={LexelLogo} alt="LexelLogo" className="lexel-logo" width="135" height="75"  />
              </div>
              <br/>
            <div>
                <a href="https://www.lawsociety.org.uk/topics/firm-accreditations/conveyancing-quality-scheme">
                  <img src={CQSLogo} alt="CQSLogo" className="cqs-logo" width="135" height="135"  />
                </a>
              </div>
            <br/>
              <div className="sra-logo">
                <iframe title="sra-logo" frameBorder="0" scrolling="no" allowTransparency="true"
                        width="135" height="75" src="https://cdn.yoshki.com/iframe/55845r.html"></iframe>
              </div>

            </Col>
            <Col md="3">
              <Nav>
                <NavItem>
                  <NavLink to="/" tag={Link}>
                    <p><b>Home</b></p>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/contact-us">
                    <p><b>Contact Us</b></p>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/team">
                    <p><b>Team</b></p>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/current-vacancies">
                    <p><b>Current Vacancies</b></p>
                  </NavLink>
                </NavItem>
              </Nav>

            </Col>
            <Col md="3">
              <h3 className="title">Follow us:</h3>
              <div className="btn-wrapper profile">
                <Button
                    className="btn-icon btn-neutral btn-round btn-simple"
                    color="default"
                    href="https://twitter.com/dienn_s?lang=en"
                    id="tooltipTwitter"
                    target="_blank"
                >
                  <i className="fab fa-twitter" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltipTwitter">
                  Follow us on Twitter
                </UncontrolledTooltip>
                 <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="https://www.facebook.com/Diennslaw"
                  id="tooltipFacebook"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltipFacebook">
                  Like us
                </UncontrolledTooltip>
                <Button
                    className="btn-icon btn-neutral btn-round btn-simple"
                    color="default"
                    href="https://instagram.com/diennslaw"
                    id="tooltipInstagram"
                    target="_blank"
                >
                  <i className="fab fa-instagram" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltipInstagram">
                  Follow us on Instagram
                </UncontrolledTooltip>
                <Button
                    className="btn-icon btn-neutral btn-round btn-simple"
                    color="default"
                    href="https://www.linkedin.com/company/diennslaw-solicitors/"
                    id="tooltipLinkedIn"
                    target="_blank"
                >
                  <div className="li-button" >
                  <img src={linkedInLogo} alt="linkedInLogo" height="20" width="20"/>
                  </div>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltipLinkedIn">
                  Follow us on LinkedIn
                </UncontrolledTooltip>


              </div>
            <br/>


            </Col>
              <Col md="3">
                < br/>
                <div className="footer-text">
                <p> Dienn’s Law Limited is authorised and regulated by the Solicitors Regulation Authority (SRA Number
                  805381), registered in England and Wales at Companies House (Company Number 12809765), and the
                  registered office is at Ground Floor Suite, 20 London Road, Horsham, West Sussex, RH12 1AY.
                  VAT Number 365 6594 55. Registered Financial Conduct Authority (FCA) Number LS805381.
                  If you would like to review our Privacy Notice, please <a href="/privacy-notice">click here</a>
                  <p><b>Email:</b>  <a href="mailto:info@diennslaw.co.uk">info@diennslaw.co.uk</a></p>
                  <p><b>Phone:</b> <a href="tel:01403 379919">01403 379919</a></p>
                </p>
                </div>


              </Col>

          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
