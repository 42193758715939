/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";


// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";
import ProfilePic from "../../emiliaprofile.jpg";
// eslint-disable-next-line
import AdamProfilePic from "../../adamprofile.jpg"
import MurshedProfilePic from "../../murshedprofile.jpg"
import ElaineProfilePic from "../../elaineprofile.jpg";
import AimeeProfilePic from "../../aimeeprofile.jpg"
class Team extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("team");
         window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("team");
    }

    render() {

        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                <h5 className="text-on-back">The Team</h5>


                                <p className="profile-description">
                                    <p><b>Emilia Dienn</b> is a Senior Solicitor and Managing Director of Dienn’s Law
                                        Solicitors who qualified on 15th September 2014. She has wealth of experience in
                                        commercial, residential, corporate and private client law. She is friendly,
                                        approachable and always procures to be
                                        flexible in accommodating your needs.</p>
                                    <br/>
                                    <img src={ProfilePic} alt="ProfilePic" className="profile-pic" align="right"/>
                                    <p>
                                        Emilia decided to open up her own law firm as she wanted to listen to her
                                        clients,
                                        take on board what they want and action them. She has created Dienn’s Law
                                        Solicitors with a personal and friendly touch in a professional manner.

                                    </p>
                                    <br/>
                                    <p>Emilia deals with debt recovery, probate, wills, Lasting Power of Attorney(s),
                                        trusts, deputyships, financial, residential, commercial, business and
                                        conveyancing
                                        transactions including lease renewals, re-financing, independent legal advice
                                        (on guarantees, debentures, legal charges, occupiers consent deeds),
                                        acquisitions
                                        and disposals for Landlords, Tenants, Sellers and Buyers for clients with both
                                        large portfolios
                                        and individuals. Emilia is an Affiliate member of Steps under the membership
                                        No. 282926.</p>
                                    <br/>
                                    <p>The residential and commercial work ranges from leasehold to freehold including
                                        Landlord and Tenant, surrenders, assignments, alterations and underletting on
                                        all types of properties, with or without finance. This can consist of
                                        leasebacks,
                                        auctions, banks, developers, retail, industrial, offices, pubs, nurseries,
                                        garden centres, care homes and other healthcare providers. She also has
                                        experience in property investment work, auctions and in particular transferring
                                        properties
                                        in and out of SIPP Property funds and disposing and acquiring businesses by way
                                        of transfers of going concern. Emilia also deals with simple and complex Wills
                                        including trust work such as property and children along with Lasting Power of
                                        Attorneys for both Health & Care Decisions and Financial Decisions.
                                    </p>
                                    <br/>
                                    <p>Emilia also deals with other areas of law that may not be listed above, if
                                        you have any queries, please do contact her and she will endeavour to assist
                                        you accordingly.</p>
                                    <br/>
                                    <br/>
                                    <br/>


                                    <br/>
                                    <br/>
                                    <img src={AdamProfilePic} alt="AdamProfilePic"
                                         className="adam-profile-pic" align="left"/>

                                    <p><b>Adam Kick</b> is a dedicated Solicitor who completed his Legal Practice Course
                                        and Master’s in Law at London Metropolitan University in 2020. With a strong
                                        focus on client care, Adam is committed to providing practical, clear, and
                                        honest legal advice. He strives to make a meaningful impact on his clients'
                                        lives by offering solutions tailored to their individual needs, while always
                                        maintaining a realistic and transparent approach.</p>
                                    <br/>
                                    <p>
                                        Outside of the office, Adam can be found either at court or representing clients
                                        at a police station, ensuring they receive the best possible legal support during
                                        critical moments. He is deeply committed to advocating for his clients' rights,
                                        no matter the setting.

                                    </p>
                                    <br/>
                                    <p>
                                        When he’s not working, Adam is kept busy with his twin daughters and their
                                        energetic German Shepherd, Bailey, which adds a personal dimension to his
                                        professional approach, grounded in patience, understanding, and a strong sense
                                        of responsibility. </p>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <img src={ElaineProfilePic} alt="ElaineProfilePic" className="elaine-profile-pic"
                                         align="right"/>
                                    <br/>
                                    <br/>
                                    <p><b>Elaine Jones</b> is a Legal and Marketing Assistant whom has provided legal
                                        secretarial support for over 25 years, working from high street law firms to
                                        large
                                        city firms. She prides herself on being hard working, adaptable, approachable
                                        and friendly with an eye for detail.</p>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <img src={MurshedProfilePic} alt="MurshedProfilePic" className="murshed-profile-pic"
                                         align="left"/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <p><b>Murshed Hussain</b> is a Trainee Solicitor who completed his Bachelor of Laws
                                        degree from the University of Hertfordshire and subsequently completed his LPC
                                        and LLM Masters in Legal Practice in the same institution. Prior to joining
                                        Dienn’s Law Solicitors, Murshed worked as a Case Handler in the Conveyancing
                                        department at a Leading Law firm, where he gained invaluable support and experience.
                                        Murshed uses his experience and knowledge to help assist clients, ensuring
                                        that they receive the best service that he offers.</p>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <img src={AimeeProfilePic} alt="AimeeProfilePic" className="aimee-profile-pic"
                                         align="right"/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <p><b>Aimee Parsons</b> is a Trainee Solicitor who completed her Bachelor of Laws degree
                                        with the Open University and then completed her LPC with the University of Law.
                                        She has previous experience as a Department Assistant within the Private Client
                                        department at her previous firm.</p>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <p><b>TESTIMONIALS: </b>
                                        <p></p>
                                    </p>
                                    <br/>
                                    <p>
                                        Mr & Mrs J - <i>“Adam has been brilliant! Friendly, helpful and always there
                                        should questions arise.”</i>
                                    </p>
                                    <br/>
                                    <p>

                                        Mr and Mrs S – <i>“Emilia was fantastic throughout our house purchase.
                                        Personable
                                        thorough and acted in a quick manner. Highly recommend”</i>
                                    </p>
                                    <br/>
                                    <p>
                                        Mr Y – <i>“Wonderful service! I’ve worked with Emilia for several years across
                                        various
                                        different business interest, and she is my go to for legal services. I really
                                        cannot
                                        recommend her and Dienn’s Law Solicitors highly enough!”</i></p>
                                </p>
                                <br/>
                                <p>
                                    Mr B - <i>"I have engaged Emilia to act for me on several personal and commercial
                                    property matters in recent years and always found her to be very efficient,
                                    diligent and prompt in her dealings. She has an excellent eye for detail and
                                    attention. It has always been a pleasure to do business with Emilia. Would
                                    definitely recommend her services.”</i></p>
                                <br/>
                                <p>
                                    Mr L - <i>“Emilia was my solicitor for the purchase of my first home. As a new home
                                    buyer,
                                    I required a lot of help and advice. Emilia was always on hand to give excellent
                                    first-class advice and support along with dealing with other agencies, such as the
                                    estate agent, mortgage broker and help to buy provider on my behalf."</i></p>
                                <br/>
                                <p>
                                    Mr & Mrs C - <i>“Emilia was quite simply amazing. She has a forensic attention to
                                    detail and explained everything to us at every step of the way. She also anticipated
                                    potential issues and really did go above and beyond in dealing with all the
                                    complexities that could otherwise have overwhelmed us. I’d recommend her to
                                    everyone."</i></p>
                                <br/>
                                <p>Mrs W – <i>“I was very impressed with the work done by Emilia and the way she dealt
                                    with both my Will and my Power of Attorney. Excellent service and would thoroughly
                                    recommend Dienn's Law to anyone.”</i>
                                </p>

                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                    <Footer />
            </>
        );
    }
}

export default Team;
