/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class CommercialProperty extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("commercial-property");
         window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("commercial-property");
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                <h5 className="text-on-back">Commercial Property</h5>
                                <p className="profile-description">
                                    <p>Commercial transactions can be complex and we work with all kinds of businesses,
                                    whether you are a landlord, tenant, Self Investment Pension Plan (SIPP)
                                        provider or trustee, owner, developer and / or an
                                    investor to buy and / or sell, let or even rent a property. We work as a team and have
                                    extensive knowledge and professional contacts. Instructing us at an early stage
                                    of your transaction will assist in providing certainty, opportunities, costs and timeframes
                                    to focus on achieving the best outcome for the challenges you may face.</p>
                                    <br />
                                    <p>Dienn's Law will work in collaboration with you, whether you require a tight or relaxed deadline. Our competitive experience means that
                                        we can deal with both complex and simpler transactions, making your life easier
                                        and allowing you to concentrate on your business whilst we focus on the law.</p>
                                    <br />
                                    <p>We deal with all kinds of transactions such as acquisitions, disposals of all
                                    types of businesses and properties such as office, retail, industrial, pubs,
                                    garden centres, gyms, garages and agricultural for both freehold and leaseholds.</p>
                                    <br />
                                    <p>
                                    Examples of some of the services that we provide are:
                                        <p></p>
                                    <ul>
                                        <li>Acquisitions and Disposals</li>
                                        <li>Board Minutes</li>
                                        <li>Conditional Contracts</li>
                                        <li>Deed of Surrender</li>
                                        <li>Deed of Variation</li>
                                        <li>Easements such as granting and reserving rights of way, use, access, services</li>
                                        <li>Heads of Terms</li>
                                        <a href="/landlord-and-tenant"><li><b>Landlord and Tenant - Leases</b></li></a>
                                        <li>Leases</li>
                                        <li>Lease Renewals</li>
                                        <li>Licence to Occupy</li>
                                        <li>Licence to Alter</li>
                                        <li>Licence to Assign or Underlet</li>
                                        <li>Negotiating Terms</li>
                                        <li>Option Agreements</li>
                                        <li>Overage Agreements</li>
                                        <li>Property Portfolio Management</li>
                                        <li>Registering and Satisfying Charges at Companies House</li>
                                        <li>Rent Authority Letters</li>
                                        <li>Rent Deposit Deed</li>
                                        <li>Sales and purchases both freehold and leasehold</li>
                                        <li>Security documentation</li>
                                        <li>Transfer of whole and / or part</li>
                                        <li>Written Resolutions</li>
                                    </ul>
                                    </p>
                                    <p>If you require assistance, we strongly recommend you contact us at the early stages
                                        to assist you on negotiations, costs and time. </p>

                                        <p>If you would like more information or have a query please feel free to
                                            <a href="/contact-us"><b> contact us.</b></a> </p>
                                        <br />

                                        <p>
                                        Other areas of Law:
                                            <ul>
                                                <a href="/agricultural"><li><u><b>Agricultural & Rural Property</b></u></li></a>
                                                <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                                <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                                <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                                <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                                <a href="/lpas"><li><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></li></a>
                                                <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                                <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                                <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                            </ul>
                                        </p>
                                </p>

                            </Col>
                        </Row>
                    </Container>

                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default CommercialProperty;
