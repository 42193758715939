/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class DebtRecoveryPricing extends React.Component {


    componentDidMount() {
         window.scrollTo(0, 0);
        document.body.classList.toggle("debt-pricing");
    }

    componentWillUnmount() {
        document.body.classList.toggle("debt-pricing");
        document.documentElement.removeEventListener(
            "mousemove",
            this.followCursor
        );
    }

    followCursor = event => {
        let posX = event.clientX - window.innerWidth / 2;
        let posY = event.clientY - window.innerWidth / 6;
        this.setState({
            squares1to6:
                "perspective(500px) rotateY(" +
                posX * 0.05 +
                "deg) rotateX(" +
                posY * -0.05 +
                "deg)",
            squares7and8:
                "perspective(500px) rotateY(" +
                posX * 0.02 +
                "deg) rotateX(" +
                posY * -0.02 +
                "deg)"
        });
    };

    render() {

        return (
            <>

                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">

                                <h5 className="text-on-back">Debt Recovery - Undisputed</h5>
                            <br/>
                                <p>At Dienn’s Law Solicitors we pride ourselves on being trusted professional advisers
                                    to individuals and businesses. We always aim to ensure people have accurate and
                                    relevant information about us and their solicitor when they are considering purchasing
                                    legal services.</p>
                                <br/>
                                <p>From 6 December 2018, we are required to publish information on our website relating
                                    to debt recovery.</p>

                                <p>Whilst we endeavour to provide information that is as accurate as possible, the pricing
                                details shown on our website are examples and should only be used as a guide as pricing
                                may vary depending on each individual circumstance. Every client will receive a client
                                care letter and terms of business setting out our costs based on their needs and the
                                specific facts of the service they require.</p>
                                <br/>
                                <p>There are different ways of seeking to recover debts and the most appropriate
                                    method will be discussed with you and agreed upon receiving your instructions.</p>
                                <br/>


                               <p><b>1.  Court Claims</b></p>
                                <p>These costs apply when your claim is in relation to an unpaid invoice which is not
                                   disputed, and where enforcement action is not needed. If the other party disputes
                                   your claim and any point or should the basis of your claim require detailed legal
                                   advice, then we will discuss any further work required and provide you with revised
                                   advice about your likely further costs, if necessary, which might be on a fixed fee
                                   basis, or on an hourly rate if more extensive work is required.</p>

                            <br/>

                                <div style={{overflow: 'auto'}}>

                                    <table className="table" >
                                        <thead>
                                        <tr>

                                            <th>Debt Value</th>
                                            <th>Court Fee</th>
                                            <th>Our Fee (+ VAT)</th>


                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Up to £500</td>
                                            <td>£50</td>
                                            <td>£300</td>
                                        </tr>
                                        <tr>
                                            <td>£501-£1,000</td>
                                            <td>£70</td>
                                            <td>£350</td>
                                        </tr>
                                        <tr>
                                            <td>£1,001-£1,500</td>
                                            <td>£80</td>
                                            <td>£400</td>
                                        </tr>
                                        <tr>
                                            <td>£1,501-£3,000</td>
                                            <td>£115</td>
                                            <td>£425</td>
                                        </tr>
                                        <tr>
                                            <td>£3,001-£5,000</td>
                                            <td>£205</td>
                                            <td>£450</td>
                                        </tr>
                                        <tr>
                                            <td>£5,001-£10,000</td>
                                            <td>£455</td>
                                            <td>£500</td>
                                        </tr>
                                        <tr>
                                            <td>£10,001-£15,000</td>
                                            <td>5% of value of claim - £500 - £750</td>
                                            <td>£950</td>
                                        </tr>
                                        <tr>
                                            <td>£15,001-£50,000</td>
                                            <td>5% of value of claim - £750 - £2,500</td>
                                            <td>£1,025</td>
                                        </tr>
                                        <tr>
                                            <td>£50,001-£100,000</td>
                                            <td>5% of value of claim - £3,650 - £5,000</td>
                                            <td>£1,275</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <p><b>Anyone wishing to proceed with a claim should note that:</b></p>
                                <ul>
                               <li> <p>The VAT element of our fee cannot be reclaimed from your debtor. In addition,
                                   any Judgment in Default will only entitle you to recover the Court fee, and for
                                   claims above £10,000 a limited amount towards your legal fees.</p></li>
                                <li><p>Interest and compensation may take the debt into a higher banding, with higher
                                    costs; and</p></li>
                                <li><p>The costs quoted above are not for matters where enforcement action, such as the
                                    Bailiff, is needed to collect your debt.</p></li>
                                </ul>

                                <p><b>Our fees include</b></p>
                               <ul>
                                <li><p>Taking your instructions and reviewing documentation.</p></li>
                                <li><p>Undertaking appropriate searches.</p></li>
                                <li><p>Sending a letter before action.</p></li>
                                <li><p>Receiving payment and sending onto you, or if the debt is not paid, drafting and
                                       issuing claim.</p></li>
                                <li><p>Where no Acknowledgement of Service of Defence is received, apply to the Court
                                       to enter Judgement in Default.</p></li>
                                <li><p>When Judgment in Default is received, write to the other side to request payment;
                                       and</p></li>
                                <li><p>If payment is not received within 14 days, providing you with advice on next
                                       steps and likely costs.</p></li>
                                </ul>


                                <p><b>Timescales:</b></p>
                                <p> Matters usually take between 2-10 weeks from receipt of instructions form you to
                                    receipt of payment from the otherside depending upon whether or not it is necessary
                                    to issue a claim. This is on the basis that the other side pays promptly on receipt
                                    of our letter before action or if proceedings are issued, upon judgement in default.
                                    If enforcement action is needed, then the matter will take longer to resolve.</p>
                                <br/>
                                <p><b>2. Statutory Demand</b></p>
                                <p>This again applies where your claim is in relation to an unpaid invoice which is not
                                    disputed, and where enforcement action is not needed. This method of debt recovery
                                    does not involve Court proceedings, and if your claim is ignored following service
                                    of the Statutory Demand, then your next step will either be the issue of Court
                                    proceedings or the issue of a Winding-Up-Petition. With regard to the latter, we
                                    will advise you further as to the likely costs of presenting a Winding-Up-Petition.
                                    With regard to the latter, we will advise you further as to the likely cost of
                                    presenting a Winding-Up-Petition and attending Court to obtain the Winding-Up-Order,
                                    but commonly your costs will be in the region of £5,000 plus VAT.</p>

                                <p>Our costs for preparing and arranging the service of a Statutory Demand upon your
                                    Debtor will on average be within the region of £400-£750 plus VAT.</p>

                                <p>Matters usually take up to 4 weeks from receipt of instruction from you to receipt
                                    of payment from the other side, failing which you will then have to decide whether
                                    to issue Court Proceedings or to present a Winding-Up-Petition.</p>
                                <br/>
                                <p><b>3. Conditional Fee Arrangements</b></p>
                                <p>We do not undertake debt recovery on a conditional fee agreement or damages-based
                                    agreement basis.</p>
                                <br/>
                                <p><b>Whom will carry out your work:</b></p>
                                <p>Work will be carried out or supervised by an experienced solicitor.</p>
                                <br/>

                                <p>If you have any questions about our service and pricing, please call a member of the
                                    team where we will be happy to talk you through the options on
                                    <a href="tel:01403 379919"> 01403 379919</a> or
                                    email us on  <a href="mailto:info@diennslaw.co.uk"> info@diennslaw.co.uk.</a>
                                </p>

                            <br/>

                                <p>
                                    Other Areas of Law:
                                    <ul>
                                        <a href="/agricultural"><li><u><b>Agricultural & Rural Property</b></u></li></a>
                                        <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                        <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                        <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                        <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                        <a href="/lpas"><li><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></li></a>
                                        <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                        <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                        <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                    </ul>
                                </p>

                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default DebtRecoveryPricing;
