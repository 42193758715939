/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

class AreasOfLaw extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("areas-of-law");
         window.scrollTo(0, 0);
    }
    componentWillUnmount() {
        document.body.classList.toggle("areas-of-law");
    }

    render() {
        return (
            <>
                <img
                    alt="..."
                    className="dots"
                    src={require("assets/img/dots.png")}
                />
                <IndexNavbar/>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                <h1 className="profile-title text-left"> Price Information</h1>
                                <h5 className="text-on-back">05</h5>
                                <p className="profile-description">

                                </p>


                            </Col>
                        </Row>
                    </Container>
                    <IndexNavbar/>
                </div>
            </>
        );
    }
}

export default AreasOfLaw;
