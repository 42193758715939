/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import Wayleave from "../../wayleave.png";
import Woodlands from "../../woodlands.png";
import Farms from "../../farms.png"
import Development from "../../development.png"
import Rural from "../../rural.png"

// eslint-disable-next-line
import styles from "./AgriculturalGrid.css"

class AgriculturalGrid extends React.Component {
    render() {
        return (


            <agriculturalgrid className="agriculturalgrid">
                <div className="row">
                    <div className="column">

                        <div className="containerWayleave">
                            <a href="/wayleave">
                                <img src={Wayleave} alt="AvatarWayleave" className="imageWayleave" />
                                <div className="centeredWayleave">Wayleave Agreements</div>
                                <div className="overlayWayleave">
                                </div>
                            </a>
                        </div>

                        <div className="containerRural">
                            <a href="/rural">
                                <img src={Rural} alt="AvatarRural" className="imageRural" />
                                <div className="centeredRural">Rural Tenancies</div>
                                <div className="overlayRural">
                                </div>
                            </a>
                        </div>
                        <div className="containerFarms">
                            <a href="/farms">
                                <img src={Farms} alt="AvatarFarms" className="imageFarms" />
                                <div className="centeredFarms">Farms, Rural Business & Property</div>
                                <div className="overlayFarms">
                                </div>
                            </a>
                        </div>

                    </div>
                        <div className="column">
                            <div className="containerDevelopment">
                                <a href="/development">
                                    <img src={Development} alt="AvatarDevelopment" className="imageDevelopment" />
                                    <div className="centeredDevelopment">Development and Option Agreements</div>
                                    <div className="overlayDevelopment">
                                    </div>
                                </a>
                            </div>

                            <div className="containerWoodlands">
                                <a href="/woodlands">
                                    <img src={Woodlands} alt="AvatarWoodlands" className="imageWoodlands" />
                                    <div className="centeredWoodlands">Woodlands</div>
                                    <div className="overlayWoodlands">
                                    </div>
                                </a>
                            </div>

                        </div>


                        </div>


            </agriculturalgrid>
            );
            }
            }
            export default AgriculturalGrid;