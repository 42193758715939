/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";
import WillsAndTrustsPic from "../../wills&trusts.jpg"

class WillsAndTrusts extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("wills-and-trusts");
         window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("wills-and-trusts");
    }


    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                {/*<h1 className="profile-title text-left">Wills and Lasting Power of Attorney(s) (LPA’s) </h1>*/}
                                <h5 className="text-on-back">Wills & Trusts</h5>

                                <p className="profile-description">

                                    <img src={WillsAndTrustsPic} alt="WillsAndTrustsPic" className="wills-and-trusts-pic" align="right" />

                                    <b>Wills:</b>
                                    <p>
                                        It is very important to have a valid Will. We believe that every person over the
                                        age of 18, who has the testamentary capacity and has the intention to create a
                                        Will, should have a Will prepared.</p>
                                        <br />
                                        <p>
                                    A Will enables anything that you own, such as your personal items, property and
                                            money to pass over to those in accordance with your wishes, with the minimum
                                            tax liability and peace of mind. If you do not have a Will when you die,
                                            this can cause difficulties for those you care about most. Your estate will
                                            be dealt with under the law of intestacy and this can be complicated and
                                            costly for your family. </p>
                                    <br/>
                                    <p>
                                            You should always check your Will and update it with your latest wishes
                                            if your circumstances change such as getting married, buying a new property,
                                            having children or getting a divorce.
                                   </p>
                                    <br/>
                                    <p>We can assist you with:</p>
                                    <ul>
                                        <li>Making your Will</li>
                                        <li>Registering your Will at the National Will Register</li>
                                        <li>Administration of Trusts</li>
                                    </ul>
                                   <br />
                                    <b>Trusts:</b>
                                    <p>
                                    Trusts can be used to protect your wealth for surviving members of your family
                                        following your death. A beneficial trust, often used when you own a property
                                        with someone, is a Declaration of Trust. These are used to define interest in
                                        land or property where one party may have put more into the property than the other.
                                        When the property is sold or if someone dies, the property is divided up in
                                        accordance with that Declaration of Trust.
                                   </p>
                                    <br/>


                                    <p>It is also important to have an up to date Lasting Power of Attorney for both
                                        Financial Decisions and Health & Care Decisions in place while you are alive.
                                        For more information, please click here
                                        <a href="/lpas"><b> - Lasting Power of Attorney (LPA)</b></a> </p>

                                    <p>If you or your loved ones have lost mental capacity, we can apply for a
                                        <a href="/probate/#deputyship_title"> <b>Deputyship</b></a> instead of a Lasting
                                        or Enduring Power of Attorney or a <a href="/probate/#court_protection_title"><b>Court of Protection </b></a>
                                         to make a Will on someone else’s behalf.</p>
                                <br/>
                                    <p>Emilia Dienn is an Affiliate Member of STEP, the Society of Trust and Estate
                                        Practitioners. Members of STEP and Will writers follow the
                                        Society’s code for Will preparation. </p>

                                    <br/>
                                    <p>
                                        If you do not have a Will, Lasting Power of Attorney -(LPA)’s or
                                        they are simply out of date, please make an appointment and
                                        <a href="/contact-us"><b> contact us</b></a> for more
                                        information.  </p>

                                    <br />
                                    <p>
                                        Other areas of Law:
                                        <ul>
                                            <a href="/agricultural"><li><u><b>Agricultural & Rural Property</b></u></li></a>
                                            <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                            <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                            <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                            <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                            <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                            <a href="/lpas"><li><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></li></a>
                                            <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                            <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                        </ul>
                                </p>
                                </p>

                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default WillsAndTrusts;
