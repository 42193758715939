/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class CurrentVacancies extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("current-vacancies");
         window.scrollTo(0, 0);

    }

    componentWillUnmount() {
        document.body.classList.toggle("current-vacancies");
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                <h5 className="text-on-back">Current Vacancies</h5>
                                <p className="profile-description">
                                    <p><b>Head of Residential Conveyancing</b></p>
                                    <ul><li><p>Dienn’s Law Solicitors are looking for an ambitious, enthusiastic, highly
                                        motivated solicitor who is at least 3 years PQE to head up our residential
                                        conveyancing department. You will need to have expertise in residential
                                        conveyancing dealing with registered and unregistered property both freehold
                                        and Leasehold, first time buyers, help to buy, shared ownership, re-mortgages,
                                        equity release, leases for both Landlord’s & Tenant’s, sales & acquisitions,
                                        investment, portfolio management and finance work. You will also need to be
                                        competent managing your own case load, have good oral and written skills,
                                        work well as part of a team and play an active role in furthering the growth
                                        of the firm.
                                    </p></li>
                                </ul>
                                    <p><b>Head of Private Client</b></p>
                                    <ul><li><p>Dienn’s Law Solicitors are looking for an ambitious, enthusiastic,
                                        highly motivated solicitor who is at least 3 years PQE to head up our private
                                        client department. You will need to have expertise in wills, power of attorneys,
                                        probate, trusts, inheritance matters, deputyship and more. You will also need to
                                        be competent managing your own case load, supervision, have good oral and written
                                        skills, work well as part of a team and play an active role in furthering the
                                        growth of the firm.
                                    </p></li>
                                    </ul>


                                        <p>If you are looking for your next career move, please contact Emilia Dienn on <a href="tel:01403 379919"> 01403 379919 </a>
                                            or email
                                            <a href="mailto:info@diennslaw.co.uk"> info@diennslaw.co.uk.</a>
                                            <br />

                                            Correspondence will be dealt with in the strictest confidence.
                                        </p>
                                    <br />

                                </p>

                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default CurrentVacancies;
