/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {Container, Card, CardBody} from "reactstrap";
import {ReactComponent as ReactLogo} from '../logo.svg';
import AreasOfLawGrid from "./pages/AreasOfLawGrid";


class Index extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("index-page");
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    document.body.classList.toggle("index-page");
  }
  render() {

      return (
      <>

        <div className="page-header header-filter">
          <div className="squares square1" />
          <div className="squares square2" />
          <div className="squares square3" />
          <div className="squares square4" />
          <div className="squares square5" />
          <div className="squares square6" />
          <div className="squares square7" />
          <div className="logo">
           <a href="/home">
                        <ReactLogo />
                        </a>
          </div>
          <Container>
            <div className="content-left company">
              <Card>
              <CardBody>
                 <p> Welcome to Dienn’s Law Limited, trading as Dienn’s Law Solicitors.  We are here to provide you with
                     legal services for both individuals and businesses whether it is
                      private client, residential or commercial transactions. </p>
              <br/>
                  <p>We work with you to overcome difficult decisions and complex transactions, no matter how large or
                    small. We are here every step of the way to protect your interests and assist you in your
                    legal requirements. </p>
                <br/>



                  <p> <b>Dienn’s Law Solicitors specialises in:</b></p>

                    <AreasOfLawGrid></AreasOfLawGrid>
              <br/>
                  <br/>

                 <p>If you would like more information or have a query please feel free to <a href="/contact-us">
                   <b>contact us.</b></a></p>

              </CardBody>
            </Card>
           </div>
          </Container>
        </div>

        <IndexNavbar />
          <Footer />

      </>
    );
  }
}

export default Index;
