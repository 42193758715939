/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";
import FarmsIcon from "../../farms_icon.png"

class Rural extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("farms");
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("farms");
    }

    render() {




        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                        <a href="/home">
                            <ReactLogo />
                        </a>
                    </div>

                    <div className="wrapper">
                        <Container className="align-items-center">
                            <Row>
                                <Col lg="12" md="6">

                                    <h5 className="text-on-back">Farms, Rural Business & Property</h5>
                                <br/>
                                    <p> We facilitate the purchase and sale of rural and agricultural properties. Our
                                        service covers everything from small residential holdings up to large rural
                                        businesses.</p>
                                <br/>
                                    <p> We can advise you on issues relating to rights of way, water and drainage,
                                        purchase and sale of chattels, countryside protection, coal mining searches,
                                        boundary disputes and more.</p>
                                <br/>
                                    <p>We can also help your rural business with succession planning, licensing,
                                        planning permission, environmental concerns, waste regulations, partnerships
                                        and contracts for the sale of produce.
                                    </p>
                                <br/>
                                    <img src={FarmsIcon} alt="FarmsIcon" className="farms-icon" />
                                    <br/>
                                    <br/>
                                    <p>If you would like more information or have a query please feel free to
                                        <a href="/contact-us"><b> contact us.</b></a></p>

                                    <br/>
                                    <br/>
                                    <p>
                                        Other Areas of Law:
                                        <ul>
                                            <a href="/agricultural"><li><u><b>Other Agricultural & Rural Property:</b></u></li></a>
                                            <ul>
                                                <a href="/development"><li><u><b>Development & Option Agreements</b></u></li></a>
                                                <a href="/rural"><li><u><b>Rural Tenencies</b></u></li></a>
                                                <a href="/wayleave"><li><u><b>Wayleave Agreements</b></u></li></a>
                                                <a href="/woodlands"><li><u><b>Woodlands</b></u></li></a>
                                            </ul>
                                            <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                            <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                            <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                            <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                            <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                            <a href="/lpas"><li><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></li></a>
                                            <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                            <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                            <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                        </ul>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Rural;
