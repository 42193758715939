/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.1.0";
import "assets/demo/demo.css";

import Index from "views/Index.js";
import ResidentialConveyancing from "./views/pages/ResidentialConveyancing";
import LandlordAndTenant from "./views/pages/LandlordAndTenant";
import CommercialProperty from "./views/pages/CommercialProperty";
import BusinessAndCompanyLaw from "./views/pages/BusinessAndCompanyLaw";
import WillsAndTrusts from "./views/pages/WillsAndTrusts";
import Financial from "./views/pages/Financial";
import WhyChooseUs from "./views/pages/WhyChooseUs";
import Team from "./views/pages/Team";
import AreasOfLaw from "./views/pages/AreasOfLaw";
import ContactUs from "./views/pages/ContactUs";
import Complaints from "./views/pages/Complaints";
import ConveyancingPricing from "./views/pages/ConveyancingPricing";
import ProbatePricing from "./views/pages/ProbatePricing";
import LPAs from "./views/pages/LPAs";
import Probate from "./views/pages/Probate";
import CurrentVacancies from "./views/pages/CurrentVacancies";
import PrivacyNotice from "./views/pages/PrivacyNotice";
import DebtRecoveryPricing from "./views/pages/DebtRecoveryPricing";
import Agricultural from "./views/pages/Agricultural";
import Wayleave from "./views/pages/Wayleave";
import Rural from "./views/pages/Rural";
import Farms from "./views/pages/Farms";
import Development from "./views/pages/Development";
import Woodlands from "./views/pages/Woodlands";
import DebtRecovery from "./views/pages/DebtRecovery";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/home" render={props => <Index {...props} />} />
      <Route
          path="/areas-of-law"
          render={props => <AreasOfLaw {...props} />}
      />
      <Route
        path="/residential-conveyancing"
        render={props => <ResidentialConveyancing {...props} />}
        />
      <Route
          path="/landlord-and-tenant"
          render={props => <LandlordAndTenant {...props} />}
      />
      <Route
          path="/commercial-property"
          render={props => <CommercialProperty {...props} />}
      />
      <Route
          path="/financial"
          render={props => <Financial {...props} />}
      />
      <Route
          path="/business-and-company-law"
          render={props => <BusinessAndCompanyLaw {...props} />}
      />
      <Route
          path="/wills-and-trusts"
          render={props => <WillsAndTrusts {...props} />}
      />
      <Route
          path="/lpas"
          render={props => <LPAs {...props} />}
      />
      <Route
          path="/probate"
          render={props => <Probate {...props} />}
      />
      <Route
          path="/why-choose-us"
          render={props => <WhyChooseUs {...props} />}
        />
      <Route
          path="/team"
          render={props => <Team {...props} />}
      />
      <Route
          path="/contact-us"
          render={props => <ContactUs {...props} />}
      />
      <Route
          path="/complaints"
          render={props => <Complaints {...props} />}
      />
      <Route
          path="/debt-recovery"
          render={props => <DebtRecovery {...props} />}
      />
      <Route
          path="/conveyancing-pricing"
          render={props => <ConveyancingPricing {...props} />}
      />
      <Route
          path="/probate-pricing"
          render={props => <ProbatePricing {...props} />}
      />
      <Route
          path="/debt-pricing"
          render={props => <DebtRecoveryPricing {...props} />}
      />
      <Route
          path="/current-vacancies"
          render={props => <CurrentVacancies {...props} />}
      />
      <Route
          path="/privacy-notice"
          render={props => <PrivacyNotice {...props} />}
      />
      <Route
          path="/agricultural"
          render={props => <Agricultural{...props} />}
      />
      <Route
          path="/wayleave"
          render={props => <Wayleave{...props} />}
      />
      <Route
          path="/rural"
          render={props => <Rural{...props} />}
      />
      <Route
          path="/farms"
          render={props => <Farms{...props} />}
      />
      <Route
          path="/development"
          render={props => <Development{...props} />}
      />
      <Route
          path="/woodlands"
          render={props => <Woodlands{...props} />}
      />
      <Redirect from="/" to="/home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
