/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class Complaints extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("complaints");
         window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("complaints");
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">

                                <p className="profile-description">

                                    <p><b>Complaints Handling Procedure</b>
                                        <p>The firm defines a complaint as an expression of dissatisfaction with the services the firm has provided.
                                            It can either be verbal or written or can be expressed in any other way. The fee earner should make a subjective assessment as to whether any action or omission is to be treated as a complaint. If in doubt, the fee earner must obtain further guidance from the COLP.</p>
                                        <br></br>
                                        <p>All our terms of business have a standard provision containing details of how
                                            a client can make a complaint in the event there is a problem. The terms of
                                            business should be sent at the outset of the transaction.</p>
                                            <br></br>
                                            <p>The name of the person with overall responsibility of complaints is
                                                Emilia Dienn. If the complaint is about Emilia Dienn, the matter can be
                                                referred to the firm’s compliance consultant.</p>
                                            <br></br>
                                            <p>In all cases where a client complains, a copy of our complaints procedure
                                            should be sent to the client, whether requested or not. If requested, the
                                                complaints procedure must be provided.</p>
                                            <br></br>
                                            <p>If a client or any other party makes a complaint, the complainant should be
                                            informed in writing how that complaint will be handled and in what timescale
                                            they will be given an initial response and or a substantive response. They
                                            should also be informed that the complaint will be dealt with promptly,
                                                fairly and free of charge.</p>
                                            <br></br>
                                            <p>All complaints must be sent to the COLP. The COLP will record the details of
                                            the complaint in the firm’s central complaints register. The COLP will
                                            review the progress of the complaint and will ensure that all time limits
                                            are adhered to or if they cannot be for any reason, that appropriate
                                                extensions are requested.</p>
                                            <br></br>
                                            <p>The person dealing with the complaint should start by identifying the cause
                                            of any problems of which the client has complained, offering any appropriate
                                            redress and correcting any unsatisfactory procedures. If it is deemed
                                                appropriate, the matter should be reported to the COLP.</p>
                                            <br></br>
                                            <p>If the complaint has not been resolved to the client’s satisfaction within 8
                                                weeks following the making of a complaint they are informed in writing:</p>
                                            <br></br>
                                            <ul>
                                                <li>The right to complaint to the Legal Ombudsman, the time frame for doing so
                                            and full details of how to contact the Legal Ombudsman; and
                                                </li>
                                            <li> If a complaint has been brought and the complaints procedure has been
                                                exhausted:</li>
                                                <ul><li>That the complaint cannot be settled;</li>
                                            <li> The name and website address of an alternative dispute resolution (ADR)
                                                approved body which would be competent to deal with the complaint; and</li>
                                            <li> Whether we agree to use the scheme operated by that body.</li>
                                                    </ul>
                                            </ul>
                                            <br></br>
                                            We will ensure that all complaints are dealt with promptly, fairly and free
                                            of charge in accordance with our complaints procedure which is as follows:
                                            <br></br>
                                        <br></br>
                                            <p><b>Complaints Procedure</b></p>
                                            <p>We are committed to high quality legal advice and client care and we are
                                            keen to resolve all complaints as soon as possible and in order to do this,
                                                we will follow our complaints handling procedure.</p>
                                            <br></br>
                                            <u>Step One:</u>
                                            <p>If you have not already done so, you should inform the Complaints Handling
                                            Director, Emilia Dienn of the full nature of the problem. You can do this by
                                            emailing her at  <a href="mailto:emilia@diennslaw.co.uk">emilia@diennslaw.co.uk</a>
                                                or writing to her at Ground Floor
                                                Suite, 20 London Road, Horsham, West Sussex RH12 1AY.</p>
                                            <br></br>
                                            <u>Step Two:</u>
                                            <p>The Complaints Handling Director will write to you acknowledging your
                                            complaint within three working days. In this letter, he shall confirm what
                                                happens next.</p>
                                            <br></br>
                                            <u>Step Three:</u>
                                            <p>The Complaints Handling Director shall then investigate the matter by
                                            reviewing the matter file and speaking to the member of staff concerned
                                            within five working days of acknowledging receipt of your complaint. If, for
                                            some reason, the matter cannot be investigated in this timeframe, then she
                                            will write to you notifying you of this together with the reason why and
                                            giving a revised timescale. Once the investigation has been completed, she
                                            shall invite you to a meeting to discuss the issue(s) you have raised and
                                            hopefully resolve the complaint. This meeting will take place within
                                            fourteen days of sending you the acknowledgement letter. Following the
                                            meeting, she shall write to you within five working days of the meeting to
                                                confirm your discussion and the solution agreed upon.</p>
                                            <br></br>
                                            <u>Step Four:</u>
                                            <p>If you do not want to or are unable to attend such a meeting, she will send
                                            you a detailed, written response, including her proposed solution, within
                                            fourteen working days of sending you the letter acknowledging receipt of
                                                your complaint.</p>
                                            <br></br>
                                            <u>Step Five:</u>
                                            <p>If you are satisfied with her response in either Step three or four above,
                                            that will be the end of the matter. However, if you are not satisfied, you
                                            should contact her again and she will arrange for a member of staff who is
                                            unconnected with the matter to review her decision. She will write to you
                                            within fourteen days of receiving your request with confirmation of the
                                            firm’s final position in relation to your complaint, outlining the reasons
                                                and any final redress that is offered.</p>
                                            <br></br>
                                            <u>Step Six:</u>
                                            <p>We have eight weeks to consider your complaint. If for any reason we are
                                            unable to resolve the problem between us within that timeframe, then you may
                                            ask the Legal Ombudsman to consider the complaint. The Legal Ombudsman may
                                                be contacted at PO Box 6167, Slough, SL1 0EH.</p>
                                            <br></br>
                                            <p>Please be aware that any complaint to the Legal Ombudsman must usually be
                                            made within six months of your having received a final written response from
                                            us about your complaint. Complaints to the Legal Ombudsman must usually be
                                            made within twelve months of the act or omission about which you are
                                            complaining occurring and; from when you should have known about or become
                                                aware that there were grounds for complaint.</p>
                                            <br></br>
                                            <p>However, the Legal Ombudsman will not accept complaints where the act or
                                                date of awareness was before 6 October 2010.</p>
                                            <br></br>
                                            <p>For further information, you should contact the Legal Ombudsman on  <a href="tel:0300 555
                                                0333">0300 555
                                                0333</a> or visit <a href="www.legalombudsman.org.uk">www.legalombudsman.org.uk</a>.</p>
                                            <br></br>

                                        <p><b>Lexcel Standard 6.6 and CPMS 6.6</b></p>
                                            <br></br>
                                            <p>Procedure for monitoring client satisfaction
                                            We are keen to get feedback from our clients on the services they receive
                                            from our staff. We do this by using a client satisfaction questionnaire that
                                                covers whether:</p>
                                            <br></br>
                                           <ul>
                                               <li>Overall satisfaction with the firm;</li>
                                            <li>Quality of service;</li>
                                            <li>General feedback on our service; and</li>
                                               <li>Call waiting feedback.</li>
                                           </ul>
                                            <br></br>
                                            <p>Feedback received via these questionnaires is reviewed as it is received.
                                            Any feedback that should be handled as a complaint is treated in accordance
                                                with our Complaints Procedure above.</p>
                                            <br></br>
                                            <p>Client feedback will also be reviewed annually by our COLP who will record
                                                the findings and outcome of the review</p>
                                            <br></br>
                                            <p>The findings of the questionnaires are considered annually as part of the
                                                business planning process.</p>
                                            <br></br>

                                        <p><b>Lexcel Standard 6.7 and CPMS 6.7</b></p>
                                            <br></br>
                                            <p>Procedure for accepting and declining instructions
                                            Instructions from new clients or new instructions from existing clients are
                                            only accepted where the work falls within one of the areas of work we
                                            undertake, and where we have sufficient resources, expertise and capacity to
                                            undertake the case. the COLP make this decision. The fee earner assesses the
                                            risk profile of all new instructions and notifies the COLP of any unusual or
                                            high-risk considerations so that appropriate action may be taken to manage
                                                these cases more closely if we decide to accept instructions.</p>
                                            <br></br>
                                            <p>We do not act for a client in circumstances where instructions are given by
                                            someone else (except in circumstances where the potential client’s friend or
                                            relative is able to produce a properly executed Lasting or Enduring Power of
                                            Attorney) or by only one client when we are acting jointly for others unless
                                            we are satisfied that the person providing the instructions has the
                                                authority to do so on behalf of all the clients.</p>
                                            <br></br>
                                            <p>We will not act for a client:</p>
                                            <br></br>
                                           <ul><li>When there are reasonable grounds for believing that the instructions are
                                            affected by duress or undue influence without satisfying ourselves that they
                                            represent the client’s wishes;</li>
                                            <li>Where it is considered that we may not be able to act in the client’s best
                                            interest, we will consider declining the instructions; or</li>
                                            <li>Where the client proposes to make a gift of significant value to you or a
                                            member of your family, or a member of the firm or their family, unless the
                                               client takes independent legal advice.</li>
                                    </ul>
                                            <br></br>
                                            <p>In all cases, if it becomes apparent that a client’s case is based on false
                                            information and/or they know that what the client is telling them is false,
                                            the firm will refuse to act for the client, or, if already acting, decline
                                                to act further.</p>
                                            <br></br>
                                            <p>We will not cease to act for a client without good reason and without
                                                providing reasonable notice.</p>
                                            <br></br>
                                           <p> If we have to cease acting for a client, we explain to them their possible
                                               options for pursuing their case.</p>
                                            <br></br>
                                            <p>Where a fee earner considers there may be circumstances where the firm
                                            should decline/cease to act for a client, they should speak to COLP, who
                                            will decide the appropriate action to take. When deciding whether to act, or
                                            terminate instructions, we will ensure that we comply with the law and the
                                                SRA Standards and Regulations.</p>
                                            <br></br>
                                            <p>The decision as to whether to decline instructions are sometimes made on an
                                            individual basis after consideration of the potential matter i.e. it is not
                                            a permitted work area, the firm does not have sufficient capacity or
                                            expertise to deal with the matter. In addition, wholesale decline of
                                            instructions may take place where we do not have any capacity to take any
                                                additional work in a particular area either due to resources or expertise.</p>
                                            <br></br>
                                            <p>The COLP will ultimately make the decision as to whether the firm should
                                                accept or decline an instruction or a set of instructions.</p>
                                            <br></br>
                                           <p> Emilia Dienn oversees our accepting & declining instructions procedures. She
                                            reviews the procedures at least annually to ensure that they remain
                                               effective.</p>
                                            <br></br>





</p>

                                        </p>


                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Complaints;
