/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {

    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class WhyChooseUs extends React.Component {


    componentDidMount() {
        document.body.classList.toggle("why-choose-us");
         window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("why-choose-us");
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                <h5 className="text-on-back">What Makes Us Different?</h5>
                                <p className="profile-description">
                                    <p>Welcome to Dienn’s Law Solicitors. Before we meet, we would like to show you why you should
                                        choose us. We are here to provide a dedicated legal service to support our
                                        clients by providing them with honest assistance and tailored information. We pay
                                        attention to detail and more importantly, we listen to you.  The more open you are, the more it enables us to
                                        understand your business or individual requirements. This will allow us to provide
                                        bespoke advice and services to guide you through your
                                        requirements and needs.</p>
                                <br/>
                                    <p>The advice you get from us will be trusted and unbiased. We assist you to
                                        overcome problems, protect your interests, profit from new opportunities or
                                        bring transactions to successful conclusions. </p>
                                    <br/>
                                    <p>We have a variety of clients that include established companies, new ventures,
                                        entrepreneurs, developers, small and local businesses (SME’s), families and
                                        individuals.</p>
                                    <br/>
                                    <p>We are accredited by the Conveyancing Quality Scheme (CQS) and Lexel. This means that
                                        Dienn’s Law Solicitors demonstrate that we are compliant with the Conveyancing
                                        Protocol and legal practice quality mark for client care, compliance and practice
                                        management. It also provides assurance to you that we have policies in place that
                                        meet high standards and are part of a recognised quality standard for a firm of
                                        solicitors providing Residential Conveyancing services. CQS and Lexcel are both
                                        accredited by the Law Society, who represent solicitors in England and Wales.</p>
                                <br/>
                                    <p>We deal with a wide range of areas of law such as:</p>
                                    <p></p>
                                    <ul>
                                        <li><a href="/agricultural"><u><b>Agricultural & Rural Property</b></u></a>
                                            &nbsp;- We can negotiate and draft Wayleave Agreements, Rural Tenancies such
                                            as Assured Shorthold Tenancies (AST’s) and Farming Business Tenancies (FBT’s),
                                            Development & Option Agreements, deal with Farms, Rural Business & Property
                                            along with Woodlands Assessments & Partitioning of Land.
                                        </li>
                                        <li><a href="/business-and-company-law"><u><b>Business Services & Company Law - Corporate</b></u></a>
                                            &nbsp;- We draft and amend contracts, dealing with company affairs such as asset or share
                                            purchase / sale agreements, board minutes, written resolutions, stock transfer forms, disclosure letters
                                            and so forth.</li>
                                        <p></p>
                                        <li><a href="/commercial-property"><u><b>Commercial Property</b></u></a>
                                            &nbsp;- Advice on negotiation of terms, specific Self Investment Pension Plan (SIPP) requirements with limitation of liability
                                            and management of commercial property transactions such as selling, buying, leases, licences,
                                            rent deposit deeds and tenancy at wills.</li>
                                        <li><a href="/debt-recovery"><u><b>Debt Recovery</b></u></a>
                                            &nbsp;- We can write to those who owe you money. Sometimes an email or a
                                            letter from us is enough for them to pay you what you are owed.</li>
                                        <li><a href="/financial"><u><b>Financial</b></u></a>
                                            &nbsp;- We draft and negotiate terms on loan agreements and provide independent legal advice.</li>
                                        <p></p>
                                        <li><a href="/landlord-and-tenant"><u><b>Landlord & Tenant - Leases</b></u></a>
                                            &nbsp;- We assist you in the negotiations of terms such as rent review, break clauses, schedule of
                                            conditions, security of tenure, assignment, underletting and acquisitions or disposals.</li>
                                        <p></p>
                                        <li><a href="/lpas"><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></a>
                                            &nbsp;- We can prepare your Lasting Power of Attorney(s) for both Health &
                                            Care Decisions and Property & Financial Decisions. We can then make sure they are
                                            registered with the Office of Public Guardian. We can also register Enduring
                                            Power of Attorneys (EPA’s) at the Office of Public Guardian where someone
                                            has lost mental capacity.</li>
                                        <p></p>
                                        <li><a href="/probate"><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></a>
                                            &nbsp;- We can administer the estate by collecting money, property and
                                            possession, applying for the Grant of Probate or Letters of Administration,
                                            paying off debts and taxes before distributing everything to beneficiaries.
                                            We can also apply for Deputyship and Court of Protection Orders for those
                                            who have lost capacity to put in place their wishes. </li>
                                        <p></p>
                                        <li><a href="/residential-conveyancing"><u><b>Residential Conveyancing</b></u></a>
                                            &nbsp;- We help you every step of the way to buy, sell and re-mortgage your home, land
                                            or buy-to-lets, whether it is a freehold or leasehold property.</li>
                                        <p></p>
                                        <li><a href="/wills-and-trusts"><u><b>Wills & Trusts</b></u></a>
                                            &nbsp;- We can prepare your valid Will and Trusts along with making sure your Will is
                                            registered at the National Will Register. </li>
                                        <p></p>
                                        <p></p>

                                    </ul>


                                </p>

                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default WhyChooseUs;
