/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class LandlordAndTenant extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("landlord-and-tenant");
         window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("landlord-and-tenant");
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                {/*<h1 className="profile-title text-left">Landlord & Tenant (Leases) </h1>*/}
                                <h5 className="text-on-back">Landlord & Tenant - Leases</h5>
                                <p className="profile-description">
                                    <p>Whether you are a landlord or tenant, no matter the size of the individual or
                                        even company portfolio, we can ensure that your rights are at a commercial
                                        advantage to protect and suit your needs. At Dienn’s Law Solicitors we deal with the
                                        disposal and acquisitions of all types of commercial property whether you are
                                        granting, renewing or ending your lease.</p>
                                    <br/>
                                    <p>
                                    We can advise you on various transactions such as:
                                    <ul>
                                        <li>Acquisition and disposal</li>
                                        <li>Alterations</li>
                                        <li>Break Clauses</li>
                                        <li>Change of use</li>
                                        <li>Insurance</li>
                                        <li>Negotiating, drafting and advising on leases</li>
                                        <li>Photographic schedule of conditions</li>
                                        <li>Renewing leases</li>
                                        <li>Rent review provisions</li>
                                        <li>Repairs, maintenance and decoration</li>
                                        <li>Security of tenure - Landlord & Tenant Act 1954</li>
                                        <li>Service charges</li>
                                        <li>Statutory notices and declarations</li>
                                        <li>Terminating leases</li>
                                        <li>Underletting, (Sub-Letting), Assigning (Selling), Sharing Occupation and Charging.</li>
                                    </ul>
                                    </p>
                                    <p>Instructing us before negotiations start means we can advise you of potential
                                        problems, negotiate advantageous terms for terminating the lease, repair
                                        obligations, break clauses, alienation provisions to underlet and / or assign
                                        your lease.</p>
                                    <br />
                                    <p>If you would like more information or have a query please feel free to
                                        <a href="/contact-us"><b> contact us.</b></a> </p>
                                    <br />

                                    <p>
                                    Other Areas of Law:
                                        <ul>
                                            <a href="/agricultural"><li><u><b>Agricultural & Rural Property</b></u></li></a>
                                            <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                            <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                            <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                            <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                            <a href="/lpas"><li><u><b>Power of Attorney(s) – LPA’s & EPA’s</b></u></li></a>
                                            <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                            <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                            <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                        </ul>
                                    </p>

                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default LandlordAndTenant;
