/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from '../../logoTab.svg';


class ResidentialConveyancing extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("residential-conveyancing");
         window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("residential-conveyancing");
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                {/*<h1 className="profile-title text-left">Residential Conveyancing</h1>*/}
                                <h5 className="text-on-back">Residential Conveyancing</h5>
                                <p className="profile-description">

                                    <p>We are here to make your transaction smoother and less stressful. We act for
                                        freeholders, leaseholders, tenants and / or landlords.</p>
                                    <br/>
                                    <p>We know how important buying or selling a property or land is to you. Whether you
                                        are an individual, first-time buyer, company or even a developer, we can help
                                        make the sale or purchase of your freehold or leasehold property as smooth as
                                        possible. Whether you are re-mortgaging, have a buy-to let, a portfolio or
                                        dealing with agricultural transactions, then please <a href="/contact-us">
                                            <b>contact us</b></a> and provide more information
                                    about your transaction. This will enable us to provide an estimation of our fees.
                                    Contacting us from the outset gives you the advantage to minimise delays, stress
                                    and may even support you on any negotiations with the other party and / or agent. </p>
                                    <br/>
                                    <p>During the transaction we will liaise with the other side, their solicitor,
                                    estate agent, mortgage provider, accountant, surveyor and local authority, where
                                    applicable. We are here to make things easier for you and help you make
                                        important decisions. We are on your side and will endeavour
                                        to ensure that your interests are protected. We will be with you every step of
                                        the way as we know how stressful moving and dealing with property matters
                                        can be.  </p>
                                    <br/>
                                    <p>
                                        We are accredited by the Conveyancing Quality Scheme (CQS) and Lexcel. This
                                        means that Dienn’s Law Solicitors demonstrate that we are compliant with the
                                        Conveyancing Protocol and legal practice quality mark for client care,
                                        compliance and practice management. It also provides assurance to you that we
                                        have policies in place that meet high standards and are part of a recognised
                                        quality standard for a firm of solicitors providing Residential Conveyancing
                                        services. CQS and Lexcel are both accredited by the Law Society, who represent
                                        solicitors in England and Wales.
                                    </p>
                                <br />
                                    <p>In addition to residential conveyancing, our friendly experienced conveyancing
                                        solicitors can help you in many other residential property matters,
                                        including:</p>

                                    <ul>
                                        <li>Agricultural and landed estates</li>
                                        <li>Easements and other rights</li>
                                        <li>Mortgages</li>
                                        <li>Rental Agreements</li>
                                        <li>Selling and Buying</li>
                                        <li>Transfer into a Trust</li>
                                        <li>Transfer of Equity</li>
                                        <li>Transfers of Part</li>
                                    </ul>

                                    <p>When buying and selling, it is always good practice to make sure your
                                        <a href="/wills-and-trusts"><b> Will</b></a> and
                                        <a href="/lpas"><b> Lasting Power of Attorney(s)</b></a> are
                                        up to date.</p>

                                    <p>If you would like more information or have a query please feel free to
                                        <a href="/contact-us"><b> contact us.</b></a> </p>
                                    <br />
                                   <p>
                                    Other areas of Law:
                                       <ul>
                                           <a href="/agricultural"><li><u><b>Agricultural & Rural Property</b></u></li></a>
                                           <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                           <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                           <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                           <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                           <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                           <a href="/lpas"><li><u><b>Power of Attorney(s) – LPA’s & EPA’s</b></u></li></a>
                                           <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                           <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                       </ul>

                                   </p>
                                </p>

                            </Col>
                        </Row>
                    </Container>

                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default ResidentialConveyancing;
