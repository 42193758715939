/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class WillsAndLPAs extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("lpas");
         window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("lpas");
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                <h5 className="text-on-back">Power of Attorney(s) - LPA’s & EPA's</h5>

                                <p>
                                    A Lasting Power of Attorney (LPA) gives someone you trust (known as an attorney)
                                    perhaps your friend, a family member or even a professional, the power to act and make
                                    decisions on your behalf. Such powers enable the attorney to go to the bank and get
                                    money out for you to pay the bills, pick up your prescription and / or even sell
                                    your house.</p>
                            <br/>
                                   <p> It is very important to have both types of LPA’s in place. So long as you are over
                                    the age of 18, have full capacity and intention to create an LPA, we can help arrange
                                       your Powers of Attorney</p>
                                <br/>
                                    <p>
                                        <b>The two types of LPA’s:</b>
                                        <p></p>

                                        <ol>
                                     <li><b> Health & Care Decisions</b> – This enables your attorney to make
                                        decisions for you as to where you live, who cares for you and any medical
                                        assistance you may need. You can place restrictions within for instance regarding life
                                        sustaining treatment. Please note that this type of LPA can only be used if
                                        you lose mental capacity; and </li>
                                        <br/>
                                    <li><b>Financial Decisions </b> – This enables your attorney
                                        to make decisions about money and property for instance paying bills,
                                        making investments, selling and buying your home. As soon as this LPA is
                                        registered, your attorney can act for you immediately, unless you place a
                                        restriction within your LPA. For instance, if you have dementia, learning
                                        difficulties, mental health problems, a stroke or head injuries.

                                    </li>
                            </ol>
                                 </p>


                                <p>LPA’s replaced the previously known Enduring Power of Attorney (EPA). EPA are still
                                valid however only relate to your finances. You may wish to therefore update your EPA
                                    and also carry out a LPA for your Health & Care Decisions. If you or your loved one has an
                                    EPA and the donor has lost mental capacity, we can register the EPA for the attorney(s)
                                    so then the EPA becomes legal so then the attorney(s) can act for the donor in
                                    accordance with their wishes.</p>
                                <br/>
                                  <p>  We can assist you with:
                                    <ul>
                                        <li>&emsp;Making your Lasting Power of Attorney for Health & Care Decisions</li>
                                        <li>&emsp;Making your Lasting Power of Attorney for Financial Decisions</li>
                                        <li>&emsp;Registering your Lasting Power of Attorney(s) with the Office of the Public Guardian</li>
                                        <li>&emsp;Registering a donor’s Enduring Power of Attorney with the Office of Public Guardian.</li>
                                        </ul>
                                  </p>
                                      <p>It is also important to have an up-to-date valid Will in place for when you
                                          pass away. For more information, please click here
                                          <a href="/wills-and-trusts"><b> - Wills & Trusts</b></a>
                                      </p>

                                <p>We know making important decisions are extremely difficult. If your loved ones have
                                    passed away, we are also here to help with dealing with Inheritance Tax and
                                    obtaining a <a href="/probate"><b>Grant of Probate</b></a> or
                                    <a href="/probate/#letter_administration_title"><b> Letters of Administration.</b></a></p>

                                    <br />

                                <p>Emilia Dienn is an Affiliate Member of STEP, the Society of Trust and Estate
                                    Practitioners. Members of STEP and Will writers follow the Society’s code for Will
                                    preparation.</p>
                                <br/>
                               <p>If you do not have a Will, Lasting Power of Attorney (LPA’s) or even if they are out
                                   of date, please make an appointment and
                                   <a href="/contact-us"><b> contact us</b></a> for more information.</p>
                                    <br />
                                    <p>
                                    Other Areas of Law:
                                        <ul>
                                            <a href="/agricultural"><li><u><b>Agricultural & Rural Property</b></u></li></a>
                                            <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                            <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                            <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                            <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                            <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                            <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                            <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                            <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                        </ul>

                                </p>

                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default WillsAndLPAs;
