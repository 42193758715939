/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class Financial extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("financial");
         window.scrollTo(0, 0);

    }

    componentWillUnmount() {
        document.body.classList.toggle("financial");
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                <h5 className="text-on-back">Financial</h5>
                                <p className="profile-description">
                                    <p>We are here to assist you with your financial needs. We work closely with your
                                        accountant to make things simpler for you.</p>
                                    <br />
                                    <p>
                                    We negotiate and draft financial agreements such as:
                                    <ul>
                                        <li>Personal Loan Agreements</li>
                                        <li>Company Loan Agreements</li>

                                    </ul>
                                    </p>

                                    <p>
                                        We provide Independent legal advice on Facility Agreements, Guarantees,
                                        Debentures, Occupiers Consent and Postponement Deeds, Legal Charges and so forth.
                                        This is required where you require separate legal advice to the other parties
                                        involved in the transaction or if you are a director of a company and that
                                        company also required needs to take legal advice and cannot use the same firm to
                                        provide such advice.
                                    </p>
                                    <p>If you would like more information or have a query please feel free to
                                        <a href="/contact-us"><b> contact us.</b></a> </p>
                                    <br />
                                    Other areas of Law:

                                    <ul>
                                        <a href="/agricultural"><li><u><b>Agricultural & Rural Property</b></u></li></a>
                                        <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                        <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                        <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                        <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                        <a href="/lpas"><li><u><b>Power of Attorney(s) – LPA’s & EPA’s</b></u></li></a>
                                        <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                        <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                        <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                    </ul>

                                </p>

                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Financial;
