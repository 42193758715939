/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import linkedInLogo from "../../assets/img/linkedin.png"

class ComponentsNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.changeColor);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeColor);
  }
  changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      this.setState({
        color: "bg-info"
      });
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  onCollapseExiting = () => {
    this.setState({
      collapseOut: "collapsing-out"
    });
  };
  onCollapseExited = () => {
    this.setState({
      collapseOut: ""
    });
  };
  scrollToDownload = () => {
    document
      .getElementById("download-section")
      .scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <Navbar
        className={"fixed-top " + this.state.color}
        color-on-scroll="100"
        expand="lg"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              to="/"
              tag={Link}
              id="navbar-brand"
            >
              <span>Home </span>

            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              Dienn's Law Solicitors
            </UncontrolledTooltip>
            <button
              aria-expanded={this.state.collapseOpen}
              className="navbar-toggler navbar-toggler"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className={"justify-content-end " + this.state.collapseOut}
            navbar
            isOpen={this.state.collapseOpen}
            onExiting={this.onCollapseExiting}
            onExited={this.onCollapseExited}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="/home">
                   Dienn's Law
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    aria-expanded={this.state.collapseOpen}
                    className="navbar-toggler"
                    onClick={this.toggleCollapse}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>

              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="/residential-conveyancing"
                  nav
                  onClick={e => e.preventDefault()}
                >
                  <p>Areas of Law</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-with-icons">
                  <DropdownItem tag={Link} to="/agricultural">
                    <i className="tim-icons icon-world" />
                    Agricultural & Rural Property
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/business-and-company-law">
                    <i className="tim-icons icon-badge" />
                    Business Services & Company Law - Corporate
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/commercial-property">
                    <i className="tim-icons icon-image-02" />
                    Commercial Property
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/debt-recovery">
                    <i className="tim-icons icon-wallet-43" />
                    Debt Recovery
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/financial">
                    <i className="tim-icons icon-money-coins" />
                    Financial
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/landlord-and-tenant">
                    <i className="tim-icons icon-bullet-list-67" />
                    Landlord & Tenant - Leases
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/lpas">
                    <i className="tim-icons icon-single-02" />
                    Power of Attorney(s) - LPA's & EPA's
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/probate">
                    <i className="tim-icons icon-single-copy-04" />
                    Probate, Intestacy, Deputyship & Court of Protection
                  </DropdownItem>
                  <DropdownItem href="/residential-conveyancing">
                    <i className="tim-icons icon-paper" />
                    Residential Conveyancing
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/wills-and-trusts">
                    <i className="tim-icons icon-align-center" />
                    Wills & Trusts
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
                <NavLink
                    href="/why-choose-us"
                    onClick="/why-choose-us"
                >
                  <p>What Makes Us Different?</p>
                </NavLink>
                <NavLink
                    href="team"
                    onClick="/why-choose-us"
                >
                  <p>The Team</p>
                </NavLink>
              <NavLink
                  href="/contact-us"
                  onClick="/contact-us"
              >
                <p>Contact Us</p>
              </NavLink>

              <UncontrolledDropdown nav>
                <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    href="/conveyacing-pricing"
                    nav
                    onClick={e => e.preventDefault()}
                ><p>Pricing</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-with-icons">
                  <DropdownItem href="/conveyancing-pricing">
                    <i className="tim-icons icon-paper" />
                    Conveyancing
                  </DropdownItem>
                  <DropdownItem href="/debt-pricing">
                    <i className="tim-icons icon-wallet-43" />
                    Debt Recovery - Undisputed
                  </DropdownItem>
                    <DropdownItem href="/probate-pricing">
                      <i className="tim-icons icon-single-copy-04" />
                      Probate & Intestacy
                    </DropdownItem>
                </DropdownMenu>
                </UncontrolledDropdown>

                  <NavItem className="p-0">
                <NavLink
                    data-placement="bottom"
                    href="https://twitter.com/dienn_s?lang=en"
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Follow us on Twitter"
                >
                  <i className="fab fa-twitter" />
                  <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
              </NavItem>
              <NavItem className="p-0">
                <NavLink
                    data-placement="bottom"
                    href="https://www.facebook.com/Diennslaw"
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Like us on Facebook"
                >
                  <i className="fab fa-facebook-square"/>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
              </NavItem>
              <NavItem className="p-0">
                <NavLink
                    data-placement="bottom"
                    href="https://instagram.com/diennslaw"
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Follow us on Instagram"
                >
                  <i className="fab fa-instagram" />
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
              </NavItem>
              <NavItem className="p-0">
                <NavLink
                    data-placement="bottom"
                    href="https://www.linkedin.com/company/diennslaw-solicitors/"
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Follow us on LinkedIn"
                >
               <img src={linkedInLogo} alt="linkedInLogo" height="18" width="18" style={{opacity: 0.8}}/>
                  <p className="d-lg-none d-xl-none">&nbsp;&nbsp;LinkedIn</p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default ComponentsNavbar;
