/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class BusinessAndCompanyLaw extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("business-and-company-law");
         window.scrollTo(0, 0);

    }

    componentWillUnmount() {
        document.body.classList.toggle("business-and-company-law");
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                        <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                {/*<h3 className="profile-title text-left"> Business and Company Law </h3>*/}
                                <h5 className="text-on-back">Business Services & Company Law – Corporate</h5>
                                <p className="profile-description">
                                    <p>We know how tough running a business can be. No matter whether you are a sole
                                    trader, limited company, partnership, SME or larger, we are experts in mergers,
                                    acquisitions and business law. We advise you in managing your transaction to your
                                    advantage and step in to negotiate terms to create certainty. We have a wealth of
                                    experience to assist in complex business issues. It is often important to involve
                                    an accountant to enhance on profit-making and tax saving opportunities, dealing
                                    with problems and creating solutions.</p>
                                <br/>
                                    <p>However, should issues and disputes arise, we will act in your best interests to
                                        help protect you and your business by providing realistic practical advice
                                        with estimated costs involved. We can negotiate contracts and prepare comprehensive
                                        agreements to achieve your commercial and financial objectives, while minimising
                                        your exposure to risk.</p>
                                <br/>
                                    <p>Some areas which may concern you and that we deal with are:
                                    <p></p>
                                    <ul>
                                        <li>Acquisitions & Mergers (Buying & Seller) businesses – Asset Purchase Agreements and Share Purchase Agreements</li>
                                        <li>Assignment of Goodwill and Business Name</li>
                                        <li>Board Minutes</li>
                                        <li>Buying and Selling assets and / or shares</li>
                                        <li>Confidentiality and non-disclosure agreements (NDA’s)</li>
                                        <li>Directors' Appointment</li>
                                        <li>Directors' Resignation</li>
                                        <li>Disclosure Letter</li>
                                        <li>Disposals</li>
                                        <li>Due Diligence</li>
                                        <li>Finance and security</li>
                                        <li>Partnership and shareholders agreements</li>
                                        <li>Setting up, managing and closing a business</li>
                                        <li>Stock Transfer Forms to transfer the Shares</li>
                                        <li>Receipt of Inventory</li>
                                        <li>Registering or Satisfying Charges at Companies House</li>
                                        <li>Restructuring a business</li>
                                        <li>Written Resolutions</li>
                                    </ul>
                                    </p>
                                    <br />
                                    <p>If you would like more information or have a query please feel free to
                                        <a href="/contact-us"><b> contact us.</b></a></p>
                                <br/>
                                    <p>Other areas of Law:
                                        <ul>
                                            <a href="/agricultural"><li><u><b>Agricultural & Rural Property</b></u></li></a>
                                            <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                            <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                            <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                            <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                            <a href="/lpas"><li><u><b>Power of Attorney(s) – LPA’s & EPA’s</b></u></li></a>
                                            <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                            <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                            <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                        </ul>
                                    </p>
                                </p>

                            </Col>
                        </Row>

                    </Container>
                </div>
                    </div>
                        <Footer />
            </>
        );
    }
}

export default BusinessAndCompanyLaw;
