import React from "react";
import Calc from "../../calculator.jpg"
import Fin from "../../financial.jpg"
import Wills from "../../wills.jpg"
import Commercial from "../../commercial.jpg"
import Landlord from "../../landlord.jpg"
import Residential from "../../residential.jpg"
import Lpa from "../../lpas.jpg"
import Probate from "../../probate.jpg"
import DebtRecovery from "../../debtrecovery.jpg"
import Agricultural from "../../agricultural.jpg"

// eslint-disable-next-line


// eslint-disable-next-line
import styles from "./AreasOfLawGrid.css"


class AreasOfLawGrid extends React.Component {
    render() {
        return (

<areasoflaw className="areasoflaw">
    <div className="row">
        <div className="column">

            <div className="containerBus">
            <a href="/business-and-company-law">
                <img src={Calc} alt="AvatarBus" className="imageBus" />
            <div className="centeredBus"> Business Services & Company Law – Corporate</div>
            <div className="overlayBus">
            </div>
            </a>
        </div>

            <div className="containerLan">
                <a href="/landlord-and-tenant">
                <img src={Landlord} alt="AvatarLan" className="imageLan" />
                <div className="centeredLan">Landlord & Tenant - Leases</div>
                <div className="overlayLan">
                </div>
                </a>
            </div>


        </div>

        <div className="column">
            <div className="containerFin">
                <a href="/financial">
            <img src={Fin} alt="AvatarFin" className="imageFin" />
            <div className="centeredFin">Financial</div>
            <div className="overlayFin">
            </div>
            </a>
        </div>

            <div className="containerRes">
                <a href="/residential-conveyancing">
                <img src={Residential} alt="AvatarRes" className="imageRes" />
                <div className="centeredRes">Residential Conveyancing</div>
                <div className="overlayRes">
                </div>
                </a>
            </div>

        </div>

        <div className="column">

        <div className="containerWills">
            <a href="/wills-and-trusts">
            <img src={Wills} alt="AvatarWills" className="imageWills" />
            <div className="centeredWills">Wills & Trusts</div>
            <div className="overlayWills">
            </div>
        </a>
        </div>


            <div className="containerCom">
                <a href="/commercial-property">
                <img src={Commercial} alt="AvatarCom" className="imageCom" />
                <div className="centeredCom">Commercial Property</div>
                <div className="overlayCom">
                </div>
                </a>
            </div>
        </div>

        <div className="column">
            <div className="containerLpa">
                <a href="/lpas">
                <img src={Lpa} alt="AvatarLpa" className="imageLpa" />
                <div className="centeredLpa">Power of Attorney(s) – LPA’s & EPA’s</div>
                <div className="overlayLpa">
                </div>
                </a>
            </div>
        </div>


        <div className="column">
            <div className="containerPro">
                <a href="/probate">
                    <img src={Probate} alt="AvatarPro" className="imagePro" />
                    <div className="centeredPro">Probate, Intestacy, Deputyship & Court of Protection</div>
                    <div className="overlayPro">
                    </div>
                </a>
            </div>
        </div>

        <div className="column">
            <div className="containerDebt">
                <a href="/debt-recovery">
                    <img src={DebtRecovery} alt="AvatarDebt" className="imageDebt" />
                    <div className="centeredDebt">Debt Recovery</div>
                    <div className="overlayDebt">
                    </div>
                </a>
            </div>
        </div>
        <div className="column">
        <div className="containerAgricultural">
            <a href="/agricultural">
                <img src={Agricultural} alt="AvatarAgricultural" className="imageAgricultural" />
                <div className="centeredAgricultural">Agricultural & Rural Property</div>
                <div className="overlayAgricultural">
                </div>
            </a>

        </div>

        </div>

        <div className="column">
        </div>
        <div className="column">
        </div>

        </div>



</areasoflaw>

    );

    }
    }
    export default AreasOfLawGrid;


