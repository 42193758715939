/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class PrivacyNotice extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("privacy-notice");
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("privacy-notice");
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                        <a href="/home">
                            <ReactLogo/>
                        </a>
                    </div>
                    <div className="wrapper">
                        <Container className="align-items-center">
                            <Row>
                                <Col lg="12" md="6">
                                    {/*<h1 className="profile-title text-left">Wills and Lasting Power of Attorney(s) (LPA’s) </h1>*/}
                                    <h5 className="text-on-back">PRIVACY NOTICE FOR CLIENTS</h5>

                                    <p>
                                        <p>

                                            Dienn’s Law Limited trading as Dienn’s Law Solicitors understands that your
                                            privacy is important to you and that you care about how your personal data
                                            is used. We respect and value the privacy of all of our clients and will
                                            only collect and use personal data in ways that are described here, and in a
                                            way that is consistent with our obligations and your rights under the law.
                                        </p>
                                        <p><b>1. Information About Us</b><br/>
                                            Dienn’s Law Limited trading as Dienn’s Law Solicitors, a limited company
                                            registered in England under company number 12809765. Its registered address
                                            is Ground Floor Suite, 20 London Road, Horsham, West Sussex, RH12 1AY.
                                            VAT number: 365 6594 55.

                                            We are authorised and regulated by the Solicitors Regulation Authority; SRA
                                            number: 805381.

                                            Data Manager: Emilia Dienn
                                            Email address: emilia@diennslaw.co.uk
                                            Telephone number: 01403 379919
                                            Postal Address: Ground Floor Suite, 20 London Road, Horsham, West Sussex, RH12 1AY.

                                            We are registered with the Financial Conduct Authority (FCA) and our number
                                            is LS805381.

                                            We are registered with the Information Commissioners Office and our number
                                            is ZA839417.

                                        </p><b>2. What Does This Notice Cover?</b><br/>
                                        This Privacy Information explains how we use your personal data: how it is
                                        collected, how it is held, and how it is processed. It also explains your rights
                                        under the law relating to your personal data.

                                        <p><b>3. What is Personal Data?</b><br/>
                                            Personal data is defined as ‘any information relating to an
                                            identifiable person who can be directly or indirectly identified in
                                            particular by reference to an identifier’.

                                            Personal data is, in simpler terms, any information about you that enables
                                            you to be identified. Personal data covers obvious information such as your
                                            name and contact details, but it also covers less obvious information such
                                            as identification numbers, electronic location data, and other online
                                            identifiers.

                                            The personal data that we use is set out in Part 5, below.
                                        </p>
                                        <p><b>4. What Are My Rights?</b><br/>
                                            Under the Data Protection Act 2018 (DPA), you have the following rights, which we will always work to
                                            uphold:

                                            a) The right to be informed about our collection and use of your personal
                                            data. This Privacy Notice should tell you everything you need to know, but
                                            you can always contact us to find out more or to ask any questions using the
                                            details in Part 11.
                                            b) The right to access the personal data we hold about you. Part 10 will
                                            tell you how to do this.
                                            c) The right to have your personal data rectified if any of your personal
                                            data held by us is inaccurate or incomplete. Please contact us using the
                                            details in Part 11 to find out more.
                                            d) The right to be forgotten, i.e. the right to ask us to delete or
                                            otherwise dispose of any of your personal data that we have. Please contact
                                            us using the details in Part 11 to find out more.
                                            e) The right to restrict (i.e. prevent) the processing of your personal
                                            data.
                                            f) The right to object to us using your personal data for a particular
                                            purpose or purposes.
                                            g) The right to data portability. This means that, if you have provided
                                            personal data to us directly, using it with your consent or for the
                                            performance of a contract, and that data is processed using automated means,
                                            you can ask us for a copy of that personal data to re-use with another
                                            service or business in many cases.
                                            h) Rights relating to automated decision-making and profiling. We do not use
                                            your personal data in this way.

                                            For more information about our use of your personal data or exercising your
                                            rights as outlined above, please contact us using the details provided in
                                            Part 11.

                                            Further information about your rights can also be obtained from the
                                            Information Commissioner’s Office or your local Citizens Advice Bureau.

                                            If you have any cause for complaint about our use of your personal data, you
                                            have the right to lodge a complaint with the Information Commissioner’s
                                            Office.
                                        </p>
                                        <p>
                                            <b>5. What Personal Data Do You Collect?</b><br/>
                                            We may collect some or all of the following personal data (this may vary
                                            according to your relationship with us):

                                            Name;
                                            Date of birth;
                                            Gender;
                                            Address;
                                            Email address;
                                            Telephone number;
                                            Business name;
                                            Job title;
                                            Profession;
                                            Payment information;
                                            Information about your preferences and interests;
                                            Your identification i.e. passport or driving licence
                                            Your proof of address i.e. utility bill or bank statement
                                            Your matter or transaction details
                                            Feedback that you provide
                                        </p>
                                        <p>
                                            <b> 6. How Do You Use My Personal Data?</b><br/>
                                            Under the DPA, we must always have a lawful basis for using personal data.
                                            This may be because the data is necessary for our performance of a contract
                                            with you, because you have consented to our use of your personal data, or
                                            because it is in our legitimate business interests to use it. Your personal
                                            data may be used for one of the following purposes:

                                            Supplying our services to you. Your personal details are required in order
                                            for us to enter into a contract with you and fulfilling the terms of our
                                            retainer with you in accordance with our client care letter and terms and
                                            conditions of business.
                                            Personalising and tailoring our services for you.
                                            Communicating with you. This may include responding to emails or calls from
                                            you.
                                            Supplying you with information by email and/or post that you have opted-in
                                            to (you may unsubscribe or opt-out at any time by sending a message to
                                            emilia@diennslaw.co.uk

                                            With your permission and/or where permitted by law, we may also use your
                                            personal data for marketing purposes, which may include contacting you by
                                            email and/or telephone and/or text message and/or post with information,
                                            news, and offers on our services. You will not be sent any unlawful
                                            marketing or spam. We will always work to fully protect your rights and
                                            comply with our obligations under the DPA and the Privacy and Electronic
                                            Communications (EC Directive) Regulations 2003, and you will always have the
                                            opportunity to opt-out.
                                        </p>
                                        <p><b>7. How Long Will You Keep My Personal Data?</b><br/>
                                            We will not keep your personal data for any longer than is necessary in
                                            light of the reason(s) for which it was first collected. Your personal data
                                            will therefore be kept for the following periods:

                                            All your personal details and data recorded in the course of your retainer
                                            with us will be held by us for a period of 6 years after your file is
                                            closed; the reason for this is because we are obliged to keep your file for
                                            regulatory purposes and to potentially defend any claim against us during
                                            the limitation period of 6 years.
                                        </p>
                                        <p><b> 8. How and Where Do You Store or Transfer My Personal Data?</b><br/>
                                            We will only store or transfer your personal data in the UK. This means that
                                            it will be fully protected under the DPA.
                                        </p>
                                        <p>
                                            <b>9. Do You Share My Personal Data?</b><br/>
                                            We will not share any of your personal data with any third parties for any
                                            purposes, subject to two important exceptions.

                                            In some limited circumstances, we may be legally required to share certain
                                            personal data, which might include yours, if we are involved in legal
                                            proceedings or complying with legal obligations, a court order, or the
                                            instructions of a government authority. The other reason we may share your
                                            data is so that we can comply with regulatory or accreditation requirements
                                            and access to your data is required for audit purposes.
                                        </p>
                                        <p><b>10. How Can I Access My Personal Data?</b><br/>
                                            If you want to know what personal data we have about you, you can ask us for
                                            details of that personal data and for a copy of it (where any such personal
                                            data is held). This is known as a “subject access request”. You can make
                                            this request verbally or in writing or by any other means that is suitable
                                            for you.

                                            To make this as easy as possible for you, a Subject Access Request Form is
                                            available for you to use. If you do wish to use this form, please ask us for
                                            a copy of it when making your request and we can either send this to you in
                                            the post or email it to you. You do not have to use this form, but it is the
                                            easiest way to tell us everything we need to know to respond to your request
                                            as quickly as possible. There is not normally any charge for a subject
                                            access request. If your request is ‘manifestly unfounded or excessive’ (for
                                            example, if you make repetitive requests) a fee may be charged to cover our
                                            administrative costs in responding.

                                            We will respond to your subject access request within one month of receiving
                                            it. Normally, we aim to provide a complete response, including a copy of
                                            your personal data within that time. In some cases, however, particularly if
                                            your request is more complex, more time may be required up to a maximum of
                                            three months from the date we receive your request. You will be kept fully
                                            informed of our progress.
                                        </p>
                                        <p><b>11. How Do I Contact You?</b><br/>
                                            To contact us about anything to do with your personal data and data
                                            protection, including to make a subject access request, please use the
                                            following details (for the attention of Emilia Dienn):

                                            Email address: emilia@diennslaw.co.uk
                                            Telephone number: 01403 379919
                                            Postal Address: Ground Floor Suite, 20 London Road, Horsham, West Sussex, RH12 1AY.
                                        </p>
                                        <p>
                                            <b>12. Changes to this Privacy Notice</b><br/>
                                            We may change this Privacy Notice from time to time. This may be necessary,
                                            for example, if the law changes, or if we change our business in a way that
                                            affects personal data protection.
                                        </p>
                                    </p>


                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}

export default PrivacyNotice;
