/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";
import WayleaveIcon from "../../wayleave_icon.png"

class Wayleave extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("wayleave");
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("wayleave");
    }

    render() {




        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                        <a href="/home">
                            <ReactLogo />
                        </a>
                    </div>

                    <div className="wrapper">
                        <Container className="align-items-center">
                            <Row>
                                <Col lg="12" md="6">

                                    <br/>
                                    <br/>

                                    <h5 className="text-on-back">Wayleave Agreements</h5>
                                <br/>

                                    <p>Wayleave Agreements permit utility companies to use your land, this can include
                                        the erection of functional structures or the running of pipes and other service
                                        media beneath or over your land. We can help advise you on your legal rights
                                        and negotiate agreements on your behalf in relation to your ambition for the land.</p>
                                <br/>

                                    <p><b>Compensation: </b> Ordinarily you will be compensated annually in relation
                                        to the granting of rights or sometimes a one off payment.</p>
                                <br/>
                                    <p><b>Termination:</b> A termination clause in the contract will set out the
                                        procedure and notice required if you wish to end the agreement.</p>
                                <br/>
                                    <p><b>Obligations:</b> Reinstatement provisions require the utility provider to
                                        restore the land back to the same condition that it was in before they started
                                        their work. Here you will set out issues such as drainage and soil structure.</p>

                                <br/>

                                    <img src={WayleaveIcon} alt="WayleaveIcon" className="wayleave-icon" />

                                    <br/>

                                    <br/>
                                    <p>If you would like more information or have a query please feel free to
                                        <a href="/contact-us"><b> contact us.</b></a></p>

                                    <br/>

                                    <p>
                                        Other Areas of Law:
                                        <ul>
                                            <a href="/agricultural"><li><u><b>Other Agricultural & Rural Property:</b></u></li></a>
                                            <ul>
                                                <a href="/development"><li><u><b>Development & Option Agreements</b></u></li></a>
                                                <a href="/farms"><li><u><b>Farms, Rural Businesses & Property</b></u></li></a>
                                                <a href="/rural"><li><u><b>Rural Tenencies</b></u></li></a>
                                                <a href="/woodlands"><li><u><b>Woodlands</b></u></li></a>
                                            </ul>
                                            <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                            <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                            <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                            <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                            <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                            <a href="/lpas"><li><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></li></a>
                                            <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                            <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                            <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                        </ul>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Wayleave;
