/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";
import RuralIcon from "../../rural_icon.png"

class Rural extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("rural");
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("rural");
    }

    render() {




        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                        <a href="/home">
                            <ReactLogo />
                        </a>
                    </div>

                    <div className="wrapper">
                        <Container className="align-items-center">
                            <Row>
                                <Col lg="12" md="6">

                                    <h5 className="text-on-back"> Rural Tenancies</h5>
                                <br/>
                                    <p>Ranging from Assured Shorthold Tenancies (AST’s) to Farm Business Tenancies (FBT’s)
                                        whether it be for grazing, a licence or other tenancy allowing commercial
                                        diversity of farms. This is vital for the profitability and growth of the
                                        agricultural industry. It may be there is a tenanted land being farmed
                                        throughout the life of the tenancy meeting various conditions.</p>
                                <br/>
                                    <p><b> Legislation: </b>  We are experts on the Agricultural Holdings Act 1986
                                        and the Agricultural Tenancies Act 1995 and are an active member of the
                                        Agricultural Law Association.</p>
                                <br/>
                                    <p><b>We can help you</b> to draft agreements, review rent, end tenancies, arrange
                                        succession and resolve disputes.
                                    </p>
                                <br/>
                                    <img src={RuralIcon} alt="RuralIcon" className="rural-icon" />
                                    <br/>

                                    <br/>
                                    <p>If you would like more information or have a query please feel free to
                                        <a href="/contact-us"><b> contact us.</b></a></p>

                                    <br/>

                                    <p>
                                        Other Areas of Law:
                                        <ul>
                                            <a href="/agricultural"><li><u><b>Other Agricultural & Rural Property:</b></u></li></a>
                                            <ul>
                                                <a href="/development"><li><u><b>Development & Option Agreements</b></u></li></a>
                                                <a href="/farms"><li><u><b>Farms, Rural Businesses & Property</b></u></li></a>
                                                <a href="/wayleave"><li><u><b>Wayleave Agreements</b></u></li></a>
                                                <a href="/woodlands"><li><u><b>Woodlands</b></u></li></a>
                                            </ul>
                                            <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                            <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                            <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                            <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                            <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                            <a href="/lpas"><li><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></li></a>
                                            <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                            <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                            <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                        </ul>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Rural;
