/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class Probate extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("debt-recovery");
         window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("debt-recovery");
    }

    render() {




        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                <h5 className="text-on-back">Debt Recovery</h5>

                                    <p>If someone owes you money and they are not paying you then your unpaid debt needs
                                        to be recovered as soon as possible.</p>
                                    <br/>
                                    <p>We deal with resolving many types of unpaid debts quickly and amicably.</p>
                                <br/>
                                    <p>Sometimes an email or letter from us is all that is needed. If you need us to
                                        negotiate for you, we can do this and then if you wish to make a claim to the
                                        courts, we can again assist you on doing this or deal with it for you.</p>
                                <br/>
                                <p>If you would like more information or have a query please feel free to
                                    <a href="/contact-us"><b> contact us.</b></a> </p>
                            <br/>
                                    <p>
                                    Other Areas of Law:
                                        <ul>
                                            <a href="/agricultural"><li><u><b>Agricultural & Rural Property</b></u></li></a>
                                            <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                            <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                            <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                            <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                            <a href="/lpas"><li><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></li></a>
                                            <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                            <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                            <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                        </ul>
                                </p>

                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Probate;
